import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { HomeRoutingModule } from './home.routing.module';
import { HomeComponent } from './home.component';
import {AngularFontAwesomeModule} from 'angular-font-awesome';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ModalComponent, ModalModule} from 'angular-custom-modal';

@NgModule({
    imports: [
        CommonModule,
        HomeRoutingModule,
        SharedModule,
        ModalModule
    ],
    declarations: [
        HomeComponent,
    ],
    exports: [
        HomeComponent,
    ]
})
export class HomeModule { }
