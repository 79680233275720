import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {ModalComponent} from 'angular-custom-modal';
import {empty} from 'rxjs/internal/Observer';
import {promisify} from 'util';
import {BookingsService} from '../shared/services/bookings.service';

@Component({
  moduleId: module.id,
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  modalTitle;
  modalContentLarge;
  modalContent;
  buttonText;
  buttonLink;
  customFunction;
  functionParam;
  times;

  @ViewChild('modalDialog') modalDialog: ModalComponent;
  router: Router;

  constructor(private _router: Router,
              private bookingService: BookingsService) {
    this.router = _router;
  }

  ngOnInit(): void {
    if (this.router.url === '/') {
      this.router.navigate(['/start']);
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  showModal(modalTitle, modalContentLarge, modalContent, buttonText = '', buttonLink = '', customFunction = null, functionParam = null, times = true): void {
    this.modalTitle = modalTitle;
    this.modalContentLarge = modalContentLarge;
    this.modalContent = modalContent;
    this.buttonText = buttonText;
    this.buttonLink = buttonLink;
    this.modalDialog.open();
    this.customFunction = customFunction;
    this.functionParam = functionParam;
    this.times = times;
  }

  logout(): void {
    // this.userService.logout();
  }

  goToAccount(): void {
    // this.router.navigate(['/account', this.userService.getCurrentUser()._id]);
  }

  getCurrentUser(): void {
    // return this.userService.getCurrentUser();
  }
}
