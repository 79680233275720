import {AfterViewInit, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit, AfterViewInit {

  status = false;
  // ANIMATION IE FIX
  firstInit = true;

  constructor() {
    console.log('StartComponent constructor');
    this.firstInit = true;
  }

  ngOnInit() {
    console.log('StartComponent init');
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.firstInit = false;
    });
  }

  toggleMenuEvent() {
    this.status = !this.status;
  }
}
