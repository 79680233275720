import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {BookingsService} from './services/bookings.service';
import {HttpClientService} from '../core/http-client.service';
import {SlotService} from './services/slot.service';
import {GuestService} from './services/guest.service';
import {FormsModule} from '@angular/forms';
import { DebounceDirective } from './directives/debounce.directive';
import {ErrorMessagesComponent} from './components/error-messages/error-messages.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule
  ],
  declarations: [
    DebounceDirective,
    ErrorMessagesComponent,
  ],
  exports: [
    TranslateModule,
    DebounceDirective,
  ],
  providers: [
    HttpClientService,
    BookingsService,
    SlotService,
    GuestService
  ]
})
export class SharedModule { }
