import {Component, OnInit, ViewChild} from '@angular/core';
import {GuestService} from '../../shared/services/guest.service';
import {Guest} from '../../shared/models/guest.model';
import {SlotService} from '../../shared/services/slot.service';
import {NgForm} from '@angular/forms';
import {HomeComponent} from '../../home/home.component';
import {IMyDateModel, IMyDefaultMonth, IMyDpOptions} from 'mydatepicker';
import {SlotResponseModel} from '../../shared/models/slotResponse.model';
import {SlotsWrapperModel} from '../../shared/models/slotsWrapper.model';
import {SlotWrapperModel} from '../../shared/models/slotWrapper.model';
import {Booking} from '../../shared/models/booking.model';
import {BookingsService} from '../../shared/services/bookings.service';
import {ModalComponent} from 'angular-custom-modal';
import {Router} from '@angular/router';
import {SlotStatus} from '../../../enums/slot.status';

@Component({
  selector: 'app-booking-detail',
  templateUrl: './booking-detail.component.html',
  styleUrls: ['./booking-detail.component.scss']
})
export class BookingDetailComponent implements OnInit {

  @ViewChild('form') form: NgForm;
  @ViewChild('slotPicker') slotPicker: ModalComponent;

  private static readonly DAY_MILLIS: number = 24*60*60*1000;

  private defaultMonth: IMyDefaultMonth = {
    defMonth: '01-2018'
  };
  guest: Guest = new Guest();
  maxGuestAmount: Number = 0;
  selectedDate: string = '';
  selectedDateForm: string = '';
  slotsCount: number = 0;
  slotsArray: [SlotWrapperModel];
  slots: SlotResponseModel;
  selectedSlotId: number;
  selectedSlotIdForm: number;
  selectedSlot;
  selectedSlotForm;
  slotStatus;
  slotStatusClass;
  checkboxValue1: boolean;
  checkboxValue2: boolean;
  // public SlotStatus = SlotStatus;
  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd.mm.yyyy',
    sunHighlight: false,
    inline: true,
    showTodayBtn: false,
    dayLabels: {su: 'S', mo: 'M', tu: 'D', we: 'M', th: 'D', fr: 'F', sa: 'S'},
    monthLabels: {
      1: 'Jan', 2: 'Feb', 3: 'Mär', 4: 'Apr', 5: 'Mai', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Okt', 11: 'Nov', 12:
        'Dez'
    },
    disableUntil: null,
    disableSince: null,
  };

  constructor(private guestService: GuestService,
              private slotService: SlotService,
              private bookingService: BookingsService,
              private homeComponent: HomeComponent,
              private router: Router
  ) { }

  ngOnInit() {
    this.guest.guest_amount = 0;
  }

    onChange() {
      // if name and id is correctly filled, get guest
      if (this.guest.booking_id && this.guest.booking_name) {
        this.guestService.findByGuest(this.guest.booking_id, this.guest.booking_name).subscribe(foundQuest => {
          this.maxGuestAmount = foundQuest.guest_amount;
          // hack start with 1 guest
          foundQuest.guest_amount = 1;
          // preserve typed 'booking_id' value
          foundQuest.booking_id = this.guest.booking_id;
          this.guest = foundQuest;
          this.selectedDateForm = '';
          this.selectedSlotForm = null;
          this.selectedSlotIdForm = null;
          this.selectedDate = '';
          this.selectedSlot = null;
          this.selectedSlotId = null;

          // prepare data for calendar
          if (this.guest) {
            // console.debug(new Date(this.guest.start));
            // console.debug(new Date(this.guest.end));
            const tomorrow: Date = this.tomorrow();
            let untilDate: Date;
            if (tomorrow > new Date(this.guest.start)) {
              untilDate = tomorrow;
            } else {
              untilDate = new Date(this.guest.start);
            }
            untilDate = this.blockBookingsBeforeFirstAugust2021(untilDate);
            const sinceDate: Date = new Date(this.guest.end);

            const disableUntilDate = new Date();
            disableUntilDate.setTime(untilDate.getTime() - BookingDetailComponent.DAY_MILLIS);
            const disableSinceDate: Date = new Date();
            disableSinceDate.setTime(sinceDate.getTime() + BookingDetailComponent.DAY_MILLIS);
            this.myDatePickerOptions.disableUntil = {year: disableUntilDate.getFullYear(), month: disableUntilDate.getMonth() + 1, day: disableUntilDate.getDate()};
            this.myDatePickerOptions.disableSince = {year: disableSinceDate.getFullYear(), month: disableSinceDate.getMonth() + 1, day: disableSinceDate.getDate()};
            this.defaultMonth = {
              defMonth: ('0' + (untilDate.getMonth() + 1)).slice(-2) + '-' +  untilDate.getFullYear()
            };
            // console.debug('myDatePickerOptions;', this.myDatePickerOptions);
          } else {
            throw new Error('Quest does not exist.');
          }
        }, err => {
          // guest not found, reset inputs
          this.guest.house_number = '';
          // this.guest.booking_name = '';
          this.guest.guest_amount = 0;
          this.maxGuestAmount = 0;
          this.selectedDateForm = '';
          this.selectedSlotForm = null;
          this.selectedSlotIdForm = null;
          this.selectedDate = '';
          this.selectedSlot = null;
          this.selectedSlotId = null;
        });
      }
    }

  onDateChanged(event: IMyDateModel) {
    // this.selectedDate = new Date(event.jsdate).toLocaleDateString();
    this.selectedDate = event.formatted;
    const choosenDay: Date = new Date(event.jsdate);
    this.getSlotsOfDay(choosenDay.getUTCFullYear() + '-' + ('0' + (choosenDay.getUTCMonth() + 1)).slice(-2) + '-' + ('0' + (choosenDay.getUTCDate() + 1)).slice(-2));
    this.selectedSlotId = null;
    this.selectedSlot = null;
    this.slotStatus = null;
  }

  onSubmit() {
    // console.log(this.checkboxValue1.valueOf());
    // console.log(this.checkboxValue2.valueOf());
    let booking: Booking = new Booking();
    booking.guest_id = this.guest._id;
    booking.slot_id = this.selectedSlotIdForm.toString();
    booking.guest_count = this.guest.guest_amount;

    this.bookingService.create(booking).subscribe({
      next: value => {
        // console.log(value.booking_code);
        this.router.navigate(['/booking/detail/' + value.booking_code]);
      },
      error: error => {
        this.homeComponent.showModal(
          'Fehler',
          '',
          'Sie haben schon eine SEEBAD Buchung getätigt. Pro Aufenthalt ist nur eine Buchung möglich.',
          'Verstanden',
          '/booking/create',
          null, null, false,);
      }
    });
  }

  isSubmitAllowed() {
    let isDisabled: Boolean = true;
    if (this.checkboxValue1 && this.checkboxValue2 && this.selectedSlotForm && this.guest.house_number) {
      isDisabled = false;
    }
    return isDisabled;
  }

  openSlotPicker() {
    if (this.guest.house_number) {
      let finishedDate: Date = new Date(this.guest.end);
      finishedDate.setHours(23, 59, 59, 999);
      if (finishedDate < this.tomorrow()) {
        this.homeComponent.showModal('Fehler', '', 'Ihre Buchung liegt in der Vergangenheit.', 'Verstanden', '/booking/create', null, null, false,);
      } else {
        const today: Date = this.today();
        const endDate: Date = new Date(this.guest.end);
        const noOfDays: number = this.getDateDiff(today, endDate);
        // console.log(noOfDays);
        this.slotService.getSlotList(noOfDays).subscribe(slots => {
          // console.log(slots);
          this.slots = slots;
          this.resetSlot();
          this.slotPicker.open();
        });
      }
    }
  }

  confirmSlotDate() {
    this.slotPicker.close();
    this.selectedDateForm = this.selectedDate;
    this.selectedSlotForm = this.selectedSlot;
    this.selectedSlotIdForm = this.selectedSlotId;
  }

  resetSlot() {
    this.selectedDate = this.selectedDateForm ? this.selectedDateForm : '';
    this.selectedSlot = this.selectedSlotForm;
    this.selectedSlotId = this.selectedSlotIdForm;

    if (this.selectedDate) {
      const dateParts = this.selectedDate.split('\.');
      this.getSlotsOfDay(dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0]);
    }
  }

  getSlotsOfDay(date: string): SlotsWrapperModel {
    if (this.slots && this.slots.days) {
      // console.log(date);
      for (let i = 0; i < this.slots.days.length; i++) {
        if (this.slots.days[i].date === date) {
          // console.log(this.slots.days[i].date);
          this.slotsCount = this.slots.days[i].slots.length;
          // console.log(this.slotsCount);
          this.slotsArray = this.slots.days[i].slots;
          // console.log(this.slotsArray);
        }
      }
      // const d: Date = new Date(this.slots.days[i].date);

      return null;
    }
    // } else {
    //   throw new Error('Slots does not exist.');
    // }
  }

  isChooseDisabled() {
    let isDisabled: Boolean = true;
    if (this.guest && this.guest._id && this.guest.guest_amount > 0) {
      isDisabled = false;
    }
    return isDisabled;
  }

  isSlotDisabled(slot: SlotWrapperModel, index: number): boolean {
    // if (index === 0) {
    //   return true;
    // }
    // if (index === 4) {
    //   return true;
    // }
    return slot.status === SlotStatus.closed;
  }

  getDateDiff(firstDate: Date, secondDate: Date): number {
    firstDate.setHours(0, 0, 0, 0);
    secondDate.setHours(0, 0, 0, 0);
    if (!(secondDate.getTime() > firstDate.getTime())) {
      return 0;
    }
    const timeDiff: number = secondDate.getTime() - firstDate.getTime();
    // include last day of accomodation
    return Math.floor((timeDiff / BookingDetailComponent.DAY_MILLIS) + 1);
  }

  add() {
    if (this.guest.guest_amount === this.maxGuestAmount) {
      return;
    } else {
      this.guest.guest_amount = this.guest.guest_amount + 1;
    }
  }

  subtract() {
    if (this.guest.guest_amount === 1) {
      return;
    } else {
      this.guest.guest_amount = this.guest.guest_amount - 1;
    }
  }

  selectSlot(slot: any) {
    // console.log(slot._id);
    this.selectedSlotId = slot._id;
    this.selectedSlot = slot.start + '-' + slot.end;

    switch (slot.status) {
      case SlotStatus.enought:
        this.slotStatus = 'Gut';
        this.slotStatusClass = SlotStatus.enought;
        break;
      case SlotStatus.less:
        this.slotStatus = 'Weniger';
        this.slotStatusClass = SlotStatus.less;
        break;
      case SlotStatus.closed:
        this.slotStatus = 'Geschlossen';
        this.slotStatusClass = SlotStatus.closed;
        break;
    }
  }

  errorBookingData() {
    this.homeComponent.showModal('Fehler', '', 'Bitte geben Sie zunächst Ihre NOVASOL-Buchungsnr. und Ihren Namen an.', 'Verstanden', '/booking/create', null, null, false);
  }

  isSlotConfirmAllowed() {
    if (this.selectedSlotId) {
      return false;
    } else {
      return true;
    }
  }

  private today(): Date {
    const today: Date = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }

  private tomorrow(): Date {
    const tomorrow: Date = new Date();
    tomorrow.setHours(0, 0, 0, 0);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  }

  private blockBookingsBeforeFirstAugust2021(untilDate: Date): Date {
    // const firstAugust2021: Date = new Date('2021-08-01T00:00:00.000+02:00');
    const firstAugust2021: Date = new Date(2021, 7, 1, 0, 0, 0, 0);
    if (firstAugust2021 > untilDate) {
      untilDate = firstAugust2021;
    }
    return untilDate;
  }
}
