export class Guest {
  _id: string;
  booking_name: string;
  booking_id: string;
  guest_amount: number;
  start: string;
  end: string;
  house_number?: string;
  core_id?: string;

  created_by?: string;
  created_time: number;
  updated_by?: string;
  updated_time: number;

  constructor() {}
}
