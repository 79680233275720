import {Component, OnInit, ViewChild} from '@angular/core';
import {BookingsService} from '../../shared/services/bookings.service';
import {Booking} from '../../shared/models/booking.model';
import {HomeComponent} from '../../home/home.component';
import {ActivatedRoute} from '@angular/router';
import {Guest} from '../../shared/models/guest.model';
import {GuestService} from '../../shared/services/guest.service';
import {ModalComponent} from 'angular-custom-modal';
import {Router} from '@angular/router';
import {Slot} from '../../shared/models/slot.model';

@Component({
  selector: 'app-booking-list',
  templateUrl: './booking-list.component.html',
  styleUrls: ['./booking-list.component.scss']
})
export class BookingListComponent implements OnInit {

  @ViewChild('modalDialog') modalDialog: ModalComponent;

  router: Router;

  constructor(private route: ActivatedRoute,
              private bookingService: BookingsService,
              private guestService: GuestService,
              private homeComponent: HomeComponent) {
  }

  booking: Booking = new Booking();
  guest: Guest = new Guest();
  mailTemplate;

  ngOnInit() {
    const params = this.route.snapshot.params;
    this.booking.slot = new Slot();
    this.bookingService.getBookingByCode(params.code).subscribe(booking => {
      // console.log(booking);
      this.booking = booking;
      this.guestService.findById(booking.guest_id).subscribe(guest => {
        this.guest = guest;
      });
    });
  }

  cancelBookingModal() {
    this.homeComponent.showModal(
      'Buchung stornieren',
      'Sind Sie sicher, dass Sie die Buchung stornieren möchten?',
      'Sie können dann einen anderen Termin für Ihren Aufenthalt aussuchen.',
      'Buchung stornieren',
      '/start',
      this.cancelBooking,
      this.booking
    );
  }

  mailTo() {
    this.mailTemplate = 'Buchungsbestätigung \n Ihre NOVASOL-Buchungsnr. \n\n ' +
      '-------> ' + this.booking.booking_code + ' <-------\n\n' +
      'Name: ' + this.guest.booking_name + '\n' +
      'Ihr Termin: ' + this.booking.day + ', ' + this.booking.slot.start + ' - ' + this.booking.slot.end + '\n\n\n' +
      'Bitte zeigen Sie diese Informationen beim \n Einchecken im SEEBAD Travemünde vor.';

    return 'mailto:?subject=Ihre Buchung im Seebad des a-ja Travemünde. Das Resort.&body=' + encodeURIComponent(this.mailTemplate);
  }

  cancelBooking(booking: Booking) {
    // console.log('Deleted booking ');
    this.bookingService.delete(booking.booking_code).subscribe(value => {
      // console.log('Deleted booking ' + value);
    });
    this.modalDialog.close();
    this.router.navigate(['/start']);
  }

}
