import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BookingListComponent} from './list/booking-list.component';
import {BookingDetailComponent} from './detail/booking-detail.component';
import {AngularFontAwesomeModule} from 'angular-font-awesome';
import {StartRoutingModule} from '../start/start-routing.module';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {ModalModule} from 'angular-custom-modal';
import {BrowserModule} from '@angular/platform-browser';
import {MyDatePickerModule} from 'mydatepicker';
import { BookingSearchComponent } from './search/booking-search.component';

@NgModule({
  imports: [
    CommonModule,
    AngularFontAwesomeModule,
    StartRoutingModule,
    FormsModule,
    SharedModule,
    ModalModule,
    BrowserModule,
    MyDatePickerModule
  ],
  declarations: [
    BookingListComponent,
    BookingDetailComponent,
    BookingSearchComponent,
  ],
})
export class BookingModule {
}
