import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { NgModel, FormControl } from '@angular/forms';
import { OnChanges } from '@angular/core/src/metadata/lifecycle_hooks';

@Component({
    selector: 'error-messages',
    templateUrl: './error-messages.component.html',
    styleUrls: ['./error-messages.component.scss']
})
export class ErrorMessagesComponent {

    @Input() inputLabel: string = '';
    @Input() inputControl: NgModel | FormControl = new FormControl();

    constructor() { }
}
