import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {BookingsService} from '../../shared/services/bookings.service';
import {BookingStatus} from '../../../enums/booking.status';
import {HomeComponent} from '../../home/home.component';

@Component({
  selector: 'app-booking-search',
  templateUrl: './booking-search.component.html',
  styleUrls: ['./booking-search.component.scss']
})
export class BookingSearchComponent implements OnInit {

  constructor(private router: Router,
              private bookingService: BookingsService,
              private homeComponent: HomeComponent) { }

  guestId: string;

  ngOnInit() {
  }

  isDisabledToSubmit() {
    if (this.guestId) {
      return false;
    } else {
      return true;
    }
  }

  navigateToDetail(): void {
    this.bookingService.getBookingByGuest(this.guestId).subscribe(foundBooking => {
      if (foundBooking && foundBooking.status === BookingStatus.open) {
        this.router.navigate(['/booking/detail/' + foundBooking.booking_code]);
      } else {
        this.homeComponent.showModal('Fehler', 'Es tut uns leid!', 'Zu dieser Eingabe haben wir leider keine Buchung hinterlegt. Bitte überprüfen Sie Ihre Angaben.', ' Angaben überprüfen ', '/booking/search', null, null, false);
      }
    }, err => {
      this.homeComponent.showModal('Fehler', 'Es tut uns leid!', 'Zu dieser Eingabe haben wir leider keine Buchung hinterlegt. Bitte überprüfen Sie Ihre Angaben.', ' Angaben überprüfen ', '/booking/search', null, null, false);
    });
  }
}
