import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RegulationsRoutingModule } from './regulations-routing.module';
import { RegulationsComponent } from './regulations.component';

@NgModule({
  imports: [
    CommonModule,
    RegulationsRoutingModule
  ],
  declarations: [RegulationsComponent]
})
export class RegulationsModule { }
