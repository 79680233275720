import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {HttpClientService} from '../../core/http-client.service';
import {Booking} from '../models/booking.model';

@Injectable()
export class BookingsService {

    private bookingUrl = 'booking/';

    constructor(private httpClient: HttpClientService) { }

  /**
   * Get booking by code
   * @param {string} code
   * @returns {Observable<Booking>}
   */
    getBookingByCode(code: string): Observable<Booking> {
        return this.httpClient.get(this.bookingUrl + code);
    }

    getBookingByGuest(guestId: string): Observable<Booking> {
        const url = this.bookingUrl + 'findByGuest?guestId=' + guestId;
        return this.httpClient.get(url);
    }
    
    create(booking: Booking): Observable<Booking> {
        return this.httpClient.post(this.bookingUrl, booking);
    }

    update(): Observable<any> {
        return null;
    }

    delete(bookingCode: string): Observable<any> {
      return this.httpClient.delete(this.bookingUrl + bookingCode);
    }

}
