export class Slot {
  available_count: number;
  start: string;
  end: string;
  status: string;

  created_by?: string;
  created_time: number;
  updated_by?: string;
  updated_time: number;

  constructor() {}
}
