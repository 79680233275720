import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {HttpClientService} from '../../core/http-client.service';
import {Guest} from '../models/guest.model';

@Injectable()
export class GuestService {

  private guestUrl = 'guest/';

  constructor(private httpClient: HttpClientService) { }

  /**
   * /v1/guest/:guestId/:guestName
   *
   * @param {string} guestId
   * @returns {Observable<Guest>}
   */
  findByGuest(guestId: string, guestName: string): Observable<Guest> {
    // const url = this.guestUrl + 'findByGuest?guestId=' + guestId + '&guestName=' + guestName;
    const url: string = `${this.guestUrl}findByGuest?guestId=${guestId}&guestName=${guestName}`;
    // console.log(url);
    return this.httpClient.get(url);
  }

  findById(id: string): Observable<Guest> {
    try {
      const url = this.guestUrl + 'findById?id=' + id;
      return this.httpClient.get(url);
    } catch (e) {
      // do nothing
    }
  }

}
