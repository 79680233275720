import { Directive, EventEmitter, HostListener, OnInit, Input, Output } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[debounce]'
})
export class DebounceDirective implements OnInit {

  @Input() delay: number = 700;
  @Output() func: EventEmitter<any> = new EventEmitter();
  private clicks = new Subject();

  constructor() { }

  ngOnInit(): void {
    const eventStream = this.clicks.pipe(
      debounceTime(this.delay)
    );

    eventStream.subscribe(e => this.func.emit(e));
  }

  @HostListener('input', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }
}
