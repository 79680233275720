import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home.component';
import { BookingListComponent } from '../booking/list/booking-list.component';
import {StartComponent} from '../start/start.component';
import {BookingDetailComponent} from '../booking/detail/booking-detail.component';
import {BookingSearchComponent} from '../booking/search/booking-search.component';
import {RegulationsComponent} from '../regulations/regulations.component';

const homeRoutes: Routes = [
    {
        path: '', component: HomeComponent, children: [
            { path: 'booking/detail/:code', component: BookingListComponent, },
            // { path: 'booking/detail/:id', component: BookingDetailComponent},
            { path: 'booking/create', component: BookingDetailComponent},
            { path: 'start', component: StartComponent, },
            { path: 'booking/search', component: BookingSearchComponent, },
            { path: 'regulations', component: RegulationsComponent, },
            // otherwise redirect to home
            { path: '**', redirectTo: '', },
        ]
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(homeRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class HomeRoutingModule { }
