import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {HttpClientService} from '../../core/http-client.service';
import {Slot} from '../models/slot.model';
import {SlotResponseModel} from '../models/slotResponse.model';

@Injectable()
export class SlotService {

  private slotgUrl = 'slot/';

  constructor(private httpClient: HttpClientService) { }

  /**
   * /v1/slot/:nextDays
   *
   * @param {number} nextDays
   * @returns {Observable<SlotResponseModel>}
   */
  getSlotList(nextDays: number): Observable<SlotResponseModel> {
    return this.httpClient.get(this.slotgUrl + nextDays);
  }

}
