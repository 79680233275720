import {Slot} from './slot.model';

export class Booking {
  _id: string;
  name: string;
  booking_code: string;
  guest_count: number;
  status: string;
  guest_id: string;
  slot_id: string;
  day: string;
  slot: Slot;

  created_by?: string;
  created_time: number;
  updated_by?: string;
  updated_time: number;

  constructor() {}
}
