import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';

/*
 * https://stackoverflow.com/questions/38879529/how-to-route-to-a-module-as-a-child-of-a-module-angular-2-rc-5
 * https://www.concretepage.com/angular-2/angular-2-4-child-routes-and-relative-navigation-example
 */
const routes: Routes = [
    { path: '', component: HomeComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            routes,
            { 
                enableTracing: false,
            }
        ),
    ],
    exports: [
        RouterModule,
    ]
})
export class AppRoutingModule { }
