import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class HttpClientService {

    serverAddress: string = environment.serverAddress;
    basicAuthUserName: string = environment.username;

    constructor(private http: HttpClient) {}

    /**
     *
     * @param apiAddress
     * @param body
     */
    post(apiAddress: string, body: any): Observable<any> {
      return this.http.post(this.serverAddress + apiAddress, body, {headers: {'Authorization': 'Basic ' + this.generateBasicToken()}});
    }

    /**
     *
     * @param apiAddress
     */
    get(apiAddress: string): Observable<any> {
        return  this.http.get(this.serverAddress + apiAddress,
          {headers: {'Authorization': 'Basic ' + this.generateBasicToken()}});
    }

    private getHttp(): Observable<HttpClient> {
            return of(this.http);
    }

    private generateBasicToken(): string {
      const token: string = btoa(this.basicAuthUserName + ':' + (this.generateBasicAuthPassword()));
      return token;
    }

    private generateBasicAuthPassword(): string {
      const now: Date = new Date();
      const generatedPassword: string = now.getUTCFullYear() + '-' + ('0' + (now.getUTCMonth() + 1)).slice(-2) + '-' + ('0' + now.getUTCDate()).slice(-2);
      return generatedPassword;
    }

    delete(apiAddress: string): Observable<any> {
      return this.http.delete(this.serverAddress + apiAddress, {headers: {'Authorization': 'Basic ' + this.generateBasicToken()}});
    }

 }
